import React from 'react';

import { HelpButton, SettingsButton, StatisticsButton } from './components';
import styles from './styles.module.scss';

interface HeaderProps {
  onClickStats?: () => void;
  onClickHelp: () => void;
  onClickSettings: () => void;
  showStats?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  onClickHelp,
  onClickStats,
  onClickSettings,
  showStats = true
}: HeaderProps) => (
  <header className={styles.headerWrapper}>
    <div className={styles.helpButtonWrapper}>
      <HelpButton onClick={onClickHelp} />
    </div>
    <h1 className={styles.header}>Wo<span id="title">rder</span>ly</h1>
    <div className={styles.headerBtnWrapper}>
      {showStats && <StatisticsButton onClick={onClickStats} />}
      <SettingsButton onClick={onClickSettings} />
    </div>
  </header>
);

export default Header;
