import React from 'react';
import styles from './styles.module.scss';

const ColoredDot: React.FC = ({ ...props }) =>
  props ? (
    <span className={styles.coloredDot} {...props}>
      &bull;
    </span>
  ) : null;

export default ColoredDot;
