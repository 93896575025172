import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useMachine } from 'react-robot';
import { useNavigate } from 'react-router-dom';
import {
  sendEnterFromSocialEvent,
  sendGameStartEvent
} from '../../shared/utils/gtmUtils';
import { Board, Header, Snackbar } from '../../components';
import Keyboard from '../../components/Keyboard/Keyboard';
import machineCreator from '../../gameLogic/machineCreator';
import {
  HelpDialog,
  SettingsDialog,
  StatsDialog
} from '../../components/Dialogs';
import { getWordNumber, isWord } from '../../gameLogic/words/wordsUtils';
import {
  GameModesEnum,
  GameState,
  GameStatusesEnum,
  ShowHandle
} from '../../shared/utils/types';
import { GAME_CONFIG } from '../../shared/utils/constants';
import {
  keys,
  loadDialogShownFromLocalStorage,
  setDialogShownInLocalStorage,
  setIsMobileInLocalStorage
} from '../../shared/utils/localStorage';

interface GamePageProps {
  mode: GameModesEnum;
  sharedWord?: string;
}

const GamePage: React.FC<GamePageProps> = ({
  mode,
  sharedWord
}: GamePageProps) => {
  const navigate = useNavigate();
  const memoizedValue = useMemo(() => machineCreator(mode), [mode]);
  const [current, send] = useMachine(memoizedValue);
  const { name: currentState, context } = current;
  const { gameState, gameStatistics } = context.games[mode];

  const { keyboardState } = context;

  const [currentGuess, setCurrentGuess] = useState<string[]>([]);

  const { gameStatus, guesses } = gameState;

  const [showHelpDialog, setShowHelpDialog] = useState<boolean>(false);
  const [showStatsDialog, setShowStatsDialog] = useState<boolean>(false);
  const [showSettingsDialog, setShowSettingsDialog] = useState<boolean>(false);

  const notEnoughLettersSnackbarRef = useRef<ShowHandle>(null);
  const notInWordListSnackbarRef = useRef<ShowHandle>(null);
  const failedWordShackbarRef = useRef<ShowHandle>(null);

  const [animateOnInvalidWord, setAnimateOnInvalidWord] =
    useState<boolean>(false);

  const [helpDialogShown, setHelpDialogShown] = useState<boolean>(
    loadDialogShownFromLocalStorage(keys.helpDialogShownKey)
  );

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setIsMobileInLocalStorage(true);
    } else {
      setIsMobileInLocalStorage(false);
    }

    if (
      gameState.evaluations.length === 0 &&
      gameState.gameStatus === GameStatusesEnum.IN_PROGRESS
    ) {
      sendGameStartEvent(getWordNumber(gameState.solution, mode), mode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (sharedWord) {
      sendEnterFromSocialEvent(getWordNumber(sharedWord, mode));
      const alreadyPlayedGame = gameStatistics.history.find(
        (state: GameState) => state.solution === sharedWord
      );
      send({
        type: 'playSharedGame',
        word: sharedWord,
        oldGame: alreadyPlayedGame
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameStatistics, send, sharedWord]);

  useEffect(() => {
    if (currentState === GameStatusesEnum.FAILED) {
      setTimeout(() => {
        failedWordShackbarRef.current?.show();
      }, 800);
    }

    if (
      currentState === GameStatusesEnum.WON ||
      currentState === GameStatusesEnum.FAILED
    ) {
      if (helpDialogShown) {
        setTimeout(() => {
          setShowStatsDialog(true);
        }, 800);
      }
    } else if (!sharedWord) {
      send({
        type: 'blockGame'
      });
    }
  }, [currentState, helpDialogShown, send, sharedWord]);

  const handleLongPress = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    const key = (e.target as HTMLButtonElement).id;

    send({
      type: 'toggleKey',
      key
    });
  };

  const handlePlayAgain = (): void => {
    send({
      type: 'playAgain'
    });
    navigate('/');
  };

  const handleCharClick = (value: string): void => {
    if (
      currentGuess.length < GAME_CONFIG[mode].MAX_WORD_LENGTH &&
      guesses.length < GAME_CONFIG[mode].MAX_CHALLENGES &&
      gameStatus === GameStatusesEnum.IN_PROGRESS
    ) {
      setCurrentGuess([...currentGuess, value]);
    }
  };

  const handleDeleteClick = (): void => {
    setCurrentGuess(currentGuess.slice(0, -1));
  };

  const handleEnterClick = (): void => {
    if (
      currentState === GameStatusesEnum.WON ||
      currentState === GameStatusesEnum.FAILED ||
      animateOnInvalidWord
    ) {
      return;
    }

    if (currentGuess.length !== GAME_CONFIG[mode].MAX_WORD_LENGTH) {
      notEnoughLettersSnackbarRef.current?.show();
      setAnimateOnInvalidWord(true);
      return;
    }

    if (!isWord(currentGuess.join(''), mode)) {
      notInWordListSnackbarRef.current?.show();
      setAnimateOnInvalidWord(true);
      return;
    }

    send({
      type: 'guessed',
      word: currentGuess.join('')
    });
    setCurrentGuess([]);
  };

  const handleHelpDialogClose = (): void => {
    setShowHelpDialog(false);
    setHelpDialogShown(true);
    setDialogShownInLocalStorage(keys.helpDialogShownKey);
  };

  return (
    <>
      <Header
        onClickHelp={() => setShowHelpDialog(true)}
        onClickStats={() => setShowStatsDialog(true)}
        onClickSettings={() => setShowSettingsDialog(true)}
      />
      <Board
        guesses={guesses}
        modeSettings={GAME_CONFIG[mode]}
        mode={mode}
        currentGuess={currentGuess}
        evaluations={gameState.evaluations}
        currentState={currentState}
        animateOnInvalidWord={animateOnInvalidWord}
      />
      <Keyboard
        guessCount={gameState.evaluations.length}
        keyboardState={keyboardState}
        onLongPress={handleLongPress}
        onCharClick={handleCharClick}
        onDeleteClick={handleDeleteClick}
        onEnterClick={handleEnterClick}
      />

      {(showHelpDialog || !helpDialogShown) && (
        <HelpDialog onClose={handleHelpDialogClose} />
      )}
      {showStatsDialog && (
        <StatsDialog
          gameStats={gameStatistics}
          mode={mode}
          gameState={gameState}
          onClose={() => setShowStatsDialog(false)}
          handlePlayAgain={handlePlayAgain}
          numOfGames={context.numOfGames}
          lastGameDate={context.lastGameDate}
        />
      )}
      {showSettingsDialog && (
        <SettingsDialog onClose={() => setShowSettingsDialog(false)} />
      )}
      <Snackbar
        ref={notEnoughLettersSnackbarRef}
        message="Not enough letters"
        timeout={1000}
        onFinish={() => setAnimateOnInvalidWord(false)}
      />
      <Snackbar
        ref={notInWordListSnackbarRef}
        message="Not in word list"
        timeout={1000}
        onFinish={() => setAnimateOnInvalidWord(false)}
      />
      <Snackbar ref={failedWordShackbarRef} timeout={15000} message={gameState.solution} />
    </>
  );
};

export default GamePage;
