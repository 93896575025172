/* eslint-disable no-unused-vars */
import { EVALUATION_STATUSES } from './constants';

export enum GameStatusesEnum {
  IN_PROGRESS = 'inGame',
  FAILED = 'failed',
  WON = 'won'
}

export type KeyboardState = { [key: string]: string };

export type GameState = {
  guesses: string[];
  evaluations: WordStatus[];
  gameStatus: GameStatusesEnum;
  solution: string;
};

export type GameStatistics = {
  history: GameState[];
  gamesPlayed: number;
  gamesWon: number;
  currentStreak: number;
  longestStreak: number;
  averageGuesses: number;
  fewestGuesses: number;
  winningGuesses: number[];
};

export type GameConfig = {
  MAX_WORD_LENGTH: number;
  MAX_CHALLENGES: number;
  GAME_WORDS: string[];
  FULL_WORDS_LIST: string[];
};

export type GameSetup = {
  [key in GameModesEnum]: GameConfig;
};

export type CharStatus =
  | typeof EVALUATION_STATUSES.PRESENT
  | typeof EVALUATION_STATUSES.CORRECT;

export type WordStatus =
  | { [key in CharStatus]: number }
  | Record<string, number>;

export enum GameModesEnum {
  // BEGINNER = 'beginner',
  STANDARD = 'standard',
  EXPERT = 'expert'
}

export type StoredGameState = {
  keyboardState: KeyboardState;
  guesses: string[];
  evaluations: WordStatus[];
  gameStatus: GameStatusesEnum;
  solution: string;
};

export type ShowHandle = {
  show: () => void;
};

export enum GtmEventsEnum {
  SHARE = 'share',
  GAME_START = 'gameStart',
  GAME_GUESS = 'gameGuess',
  GAME_OVER = 'gameOver',
  PLAY_AGAIN = 'playAgain',
  ENTER_FROM_SOCIAL = 'enterFromSocial'
}
