import beginnerWordList from '../../gameLogic/words/beginnerWordList';
import fullBeginnerWordList from '../../gameLogic/words/fullBeginnerList';
import standardWordList from '../../gameLogic/words/standardWordList';
import fullStandardList from '../../gameLogic/words/fullStandardList';
import { GameSetup, GameModesEnum } from './types';

export const MODES = {
  STANDARD: 'standard',
  EXPERT: 'expert'
};

export const MODES_SETTINGS = {
  STANDARD: {
    MAX_WORD_LENGTH: 5,
    MAX_CHALLENGES: 6,
    BOARD_HEIGHT: '60%'
  },
  EXPERT: {
    MAX_WORD_LENGTH: 6,
    MAX_CHALLENGES: 8,
    BOARD_HEIGHT: '85%'
  }
};

export const ABSENT_STATUS = 'absent';

export const ALERT_TIME_MS = 2000;

export const KEYBOARD = {
  LETTERS: {
    FIRST_ROW: ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P'],
    SECOND_ROW: ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
    THIRD_ROW: ['Z', 'X', 'C', 'V', 'B', 'N', 'M']
  },
  ENTER_KEY: 'Enter',
  DELETE_KEY: 'Delete'
};

export const NUMBER_OF_GAMES_PER_DAY = 2;

export const GAME_CONFIG: GameSetup = {
  [GameModesEnum.STANDARD]: {
    MAX_WORD_LENGTH: 4,
    MAX_CHALLENGES: 6,
    GAME_WORDS: beginnerWordList,
    FULL_WORDS_LIST: fullBeginnerWordList
  },
  [GameModesEnum.EXPERT]: {
    MAX_WORD_LENGTH: 5,
    MAX_CHALLENGES: 8,
    GAME_WORDS: standardWordList,
    FULL_WORDS_LIST: fullStandardList
  },
  // [GameModesEnum.EXPERT]: {
  //   MAX_WORD_LENGTH: 6,
  //   MAX_CHALLENGES: 8,
  //   GAME_WORDS: expertWordList,
  //   FULL_WORDS_LIST: fullExpertWordList
  // }
};

export const GAME_CONTEXT_PARTS = {
  KEYBOARD_STATE: 'keyboardState',
  STANDARD: 'standard',
  EXPERT: 'expert',
  MODE: 'mode',
  NUM_OF_GAMES: 'numOfGames',
  LAST_GAME_DATE: 'lastGameDate'
};

export const EVALUATION_STATUSES = {
  CORRECT: 'correct',
  PRESENT: 'present'
};

export const GAME_TITLE = 'WORDERLY';
export const GAME_TITLE_LOWERCASE = 'Worderly';
export const GAME_TITLE_LOWERCASE_UNDERLINE = 'Wo<span id="title">rder</span>ly';
