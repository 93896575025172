import React, { useEffect, useState } from 'react';
import { useLongPress } from '../../shared/hooks/useLongPress2';
import { ABSENT_STATUS, KEYBOARD } from '../../shared/utils/constants';
import { KeyboardState } from '../../shared/utils/types';
import { Key } from './components';
import styles from './styles.module.scss';
import { ReactComponent as BackspaceIcon } from '../../assets/icons/backspace.svg';
import colors from '../../shared/scss/colors.module.scss';
import {
  keys,
  loadDialogShownFromLocalStorage,
  setDialogShownInLocalStorage
} from '../../shared/utils/localStorage';
import { KeyboardDialog } from '../Dialogs';

type KeyboardProps = {
  onCharClick: (_value: string) => void;
  onDeleteClick: () => void;
  onEnterClick: () => void;
  onLongPress: (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  guessCount: number;
  keyboardState: KeyboardState;
};

const Keyboard: React.FC<KeyboardProps> = ({
  onEnterClick,
  onDeleteClick,
  onCharClick,
  onLongPress,
  guessCount,
  keyboardState
}: KeyboardProps) => {
  const [keyboardHoldDialogShown, setKeyboardHoldDialogShown] =
    useState<boolean>(
      loadDialogShownFromLocalStorage(keys.keyboardHoldDialogKey)
    );

  useEffect(() => {
    const listener = (e: KeyboardEvent): void => {
      if (e.code === 'Enter') {
        onEnterClick();
      } else if (e.code === 'Backspace') {
        onDeleteClick();
      } else {
        const key = e.key.toUpperCase();
        if (key.length === 1 && key >= 'A' && key <= 'Z') {
          onCharClick(key);
        }
      }
    };
    window.addEventListener('keyup', listener);
    return () => {
      window.removeEventListener('keyup', listener);
    };
  }, [onEnterClick, onDeleteClick, onCharClick]);

  const handleClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    const key = (e.target as HTMLButtonElement).id;
    if (key === KEYBOARD.ENTER_KEY) {
      onEnterClick();
    } else if (key === KEYBOARD.DELETE_KEY) {
      onDeleteClick();
    } else {
      onCharClick(key);
    }
    e.currentTarget.blur();
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const longPressEvent = useLongPress(onLongPress, {
    onCancel: handleClick,
    captureEvent: true,
  });

  const handleKeyboardDialogClose = (): void => {
    setKeyboardHoldDialogShown(true);
    setDialogShownInLocalStorage(keys.keyboardHoldDialogKey);
  };

  return (
    <>
      {!keyboardHoldDialogShown && guessCount >= 3 && (
        <KeyboardDialog onClose={handleKeyboardDialogClose} />
      )}
      <div className={styles.keyboard}>
        <div className={styles.defaultRow}>
          {KEYBOARD.LETTERS.FIRST_ROW.map((key) => (
            <Key
              value={key}
              key={key}
              isAbsent={
                keyboardState ? keyboardState[key] === ABSENT_STATUS : false
              }
              {...longPressEvent}
            />
          ))}
        </div>
        <div className={styles.secondRow}>
          {KEYBOARD.LETTERS.SECOND_ROW.map((key) => (
            <Key
              value={key}
              key={key}
              isAbsent={
                keyboardState ? keyboardState[key] === ABSENT_STATUS : false
              }
              {...longPressEvent}
            />
          ))}
        </div>
        <div className={styles.defaultRow}>
          <Key width={65.5} value={KEYBOARD.ENTER_KEY} onClick={handleClick} />
          {KEYBOARD.LETTERS.THIRD_ROW.map((key) => (
            <Key
              value={key}
              key={key}
              isAbsent={
                keyboardState ? keyboardState[key] === ABSENT_STATUS : false
              }
              {...longPressEvent}
            />
          ))}
          <Key width={65.5} value={KEYBOARD.DELETE_KEY} onClick={handleClick}>
            <BackspaceIcon id={KEYBOARD.DELETE_KEY} fill={colors.dimmedWhite} />
          </Key>
        </div>
      </div>
    </>
  );
};

export default Keyboard;
