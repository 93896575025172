import React from 'react';
import { GameConfig } from '../../../../../shared/utils/types';
import Tile from '../../Tile/Tile';
import styles from './styles.module.scss';

interface EmptyRowProps {
  modeSettings: GameConfig;
}

export const EmptyRow: React.FC<EmptyRowProps> = ({
  modeSettings
}: EmptyRowProps) => {
  const emptyCells = Array.from(Array(modeSettings.MAX_WORD_LENGTH));

  return (
    <div
      className={styles.emptyRow}
      style={{
        gridTemplateColumns: `repeat(${modeSettings.MAX_WORD_LENGTH}, 1fr)`
      }}
    >
      {emptyCells.map((_, i) => (
        <Tile key={i} />
      ))}
    </div>
  );
};

export default EmptyRow;
