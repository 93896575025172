import { createMachine, guard, state, transition, reduce } from 'robot3';
import { GameModesEnum } from '../shared/utils/types';
import {
  blockGameGuard,
  failedGuard,
  guessedGuard,
  playAgainDisplayOldFailedGameGuard,
  playAgainDisplayOldWonGameGuard,
  playAgainGuard,
  playAgainSharedWordGuard,
  wonGuard
} from './guards';
import {
  blockGameReducer,
  failedReducer,
  guessedReducer,
  oldGameReducer,
  playAgainReducer,
  playAgainSharedWord,
  toggleKeyReducer,
  wonReducer
} from './reducers';
import { getInitialState } from './state';
import {
  IGameContext,
  IGuessedEvent,
  IToggleKeyEvent,
  ICEvent,
  ISharedWordEvent
} from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function machineCreator(mode: GameModesEnum): any {
  const initialContext = getInitialState(mode);
  const { gameState } = initialContext.games[mode];

  const currentState: 'inGame' | 'won' | 'failed' = gameState.gameStatus as
    | 'inGame'
    | 'won'
    | 'failed';

  initialContext.mode = mode;

  return createMachine(
    currentState,
    {
      inGame: state(
        transition(
          'guessed',
          'won',
          guard<IGameContext, IGuessedEvent>(wonGuard),
          reduce<IGameContext, IGuessedEvent>(wonReducer)
        ),
        transition(
          'guessed',
          'failed',
          guard<IGameContext, IGuessedEvent>(failedGuard),
          reduce<IGameContext, IGuessedEvent>(failedReducer)
        ),
        transition(
          'guessed',
          'inGame',
          guard<IGameContext, IGuessedEvent>(guessedGuard),
          reduce<IGameContext, IGuessedEvent>(guessedReducer)
        ),
        transition(
          'toggleKey',
          'inGame',
          reduce<IGameContext, IToggleKeyEvent>(toggleKeyReducer)
        ),
        transition(
          'toggleKey',
          'inGame',
          reduce<IGameContext, IToggleKeyEvent>(toggleKeyReducer)
        ),
        transition(
          'playSharedGame',
          'inGame',
          guard<IGameContext, ISharedWordEvent>(playAgainSharedWordGuard),
          reduce<IGameContext, ISharedWordEvent>(playAgainSharedWord)
        ),
        transition(
          'playSharedGame',
          'won',
          guard<IGameContext, ISharedWordEvent>(playAgainDisplayOldWonGameGuard),
          reduce<IGameContext, ISharedWordEvent>(oldGameReducer)
        ),
        transition(
          'playSharedGame',
          'failed',
          guard<IGameContext, ISharedWordEvent>(
            playAgainDisplayOldFailedGameGuard
          ),
          reduce<IGameContext, ISharedWordEvent>(oldGameReducer)
        ),
        transition(
          'blockGame',
          'won',
          guard<IGameContext, ISharedWordEvent>(
            blockGameGuard
          ),
          reduce<IGameContext, ISharedWordEvent>(blockGameReducer)
        )
      ),
      won: state(
        transition(
          'playAgain',
          'inGame',
          guard<IGameContext, ISharedWordEvent>(playAgainGuard),
          reduce<IGameContext, ICEvent>(playAgainReducer)
        ),
        transition(
          'playSharedGame',
          'inGame',
          guard<IGameContext, ISharedWordEvent>(playAgainSharedWordGuard),
          reduce<IGameContext, ISharedWordEvent>(playAgainSharedWord)
        ),
        transition(
          'playSharedGame',
          'won',
          guard<IGameContext, ISharedWordEvent>(playAgainDisplayOldWonGameGuard),
          reduce<IGameContext, ISharedWordEvent>(oldGameReducer)
        ),
        transition(
          'playSharedGame',
          'failed',
          guard<IGameContext, ISharedWordEvent>(
            playAgainDisplayOldFailedGameGuard
          ),
          reduce<IGameContext, ISharedWordEvent>(oldGameReducer)
        )
      ),
      failed: state(
        transition(
          'playAgain',
          'inGame',
          guard<IGameContext, ISharedWordEvent>(playAgainGuard),
          reduce<IGameContext, ICEvent>(playAgainReducer)
        ),
        transition(
          'playSharedGame',
          'inGame',
          guard<IGameContext, ISharedWordEvent>(playAgainSharedWordGuard),
          reduce<IGameContext, ISharedWordEvent>(playAgainSharedWord)
        ),
        transition(
          'playSharedGame',
          'won',
          guard<IGameContext, ISharedWordEvent>(playAgainDisplayOldWonGameGuard),
          reduce<IGameContext, ISharedWordEvent>(oldGameReducer)
        ),
        transition(
          'playSharedGame',
          'failed',
          guard<IGameContext, ISharedWordEvent>(
            playAgainDisplayOldFailedGameGuard
          ),
          reduce<IGameContext, ISharedWordEvent>(oldGameReducer)
        )
      )
    },
    () => initialContext
  );
}

export default machineCreator;
