import React, { memo } from 'react';
import Tile from '../../Tile/Tile';
import styles from './styles.module.scss';
import ColoredDot from '../../../../ColoredDot/ColoredDot';
import { EVALUATION_STATUSES } from '../../../../../shared/utils/constants';
import { renderNTimes } from '../../../../../shared/utils/helpers';
import {
  GameConfig,
  GameStatusesEnum,
  WordStatus
} from '../../../../../shared/utils/types';

interface CompletedRowProps {
  guess: string;
  modeSettings: GameConfig;
  status: WordStatus;
  mode: string;
  currentState?: string;
  isLastRow?: boolean;
  rowStyles?: string;
  evaluationsStyles?: string;
}

const CompletedRow: React.FC<CompletedRowProps> = ({
  guess,
  modeSettings,
  mode,
  status,
  currentState,
  isLastRow,
  rowStyles = '',
  evaluationsStyles = ''
}: CompletedRowProps) => {
  const dataObj = {
    'data-game-mode': mode
  };

  const getTileDataObj = (): object => {
    if (isLastRow) {
      if (currentState === GameStatusesEnum.WON) {
        return {
          'data-tile-color': EVALUATION_STATUSES.CORRECT
        };
      }
    }
    return {
      'data-tile-color': 'traansparent'
    };
  };

  const getColoredDotDataObj = (name: string): object => ({
    'data-tile-color':
      EVALUATION_STATUSES.PRESENT === name
        ? EVALUATION_STATUSES.PRESENT
        : EVALUATION_STATUSES.CORRECT
  });
  return (
    <div className={styles.completedRowWrapper}>
      <div
        className={`${rowStyles} ${styles.completedRow} ${
          currentState === GameStatusesEnum.WON && isLastRow ? styles.win : ''
        }`}
        style={{
          gridTemplateColumns: `repeat(${modeSettings.MAX_WORD_LENGTH}, 1fr)`
        }}
      >
        {guess.split('').map((letter, i) => (
          <Tile key={i} value={letter} {...getTileDataObj()} />
        ))}
      </div>
      <div
        className={`${evaluationsStyles} ${styles.evaluations}`}
        {...dataObj}
      >
        {Object.entries(status).map(([name, counter]) =>
          renderNTimes(counter, (_, i) => (
            <ColoredDot key={i} {...getColoredDotDataObj(name)} />
          ))
        )}
      </div>
    </div>
  );
};

export default memo(CompletedRow);
