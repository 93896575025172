import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ShowHandle } from '../../shared/utils/types';
import styles from './styles.module.scss';

interface SnackbarProps {
  message: string;
  timeout: number;
  onFinish?: () => void;
}

const getShowStyles = (timeout: number): { visibility: string; animation: string; } => ({
  visibility: 'visible',
  animation: `fadeIn 0.3s, fadeOut 0.3s ${timeout}s`
});

const hideStyles = {
  visibility: 'hidden'
};

const Snackbar = forwardRef<ShowHandle, SnackbarProps>(
  ({ message, onFinish, timeout = 1000 }, ref) => {
    const [showSnackbar, setShowSnackbar] = useState(false);

    useImperativeHandle(ref, () => ({
      show() {
        if (showSnackbar) return;
        setShowSnackbar(true);
        setTimeout(() => {
          setShowSnackbar(false);
          if (onFinish) onFinish();
        }, timeout);
      }
    }));

    return (
      <div
        className={`${styles.snackbar}`}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        style={showSnackbar ? getShowStyles(timeout) : hideStyles}
      >
        <div className={styles.message}>{message}</div>
      </div>
    );
  }
);

Snackbar.displayName = 'Snackbar';

export default Snackbar;
