import React from 'react';
import { GameModesEnum, WordStatus } from '../../../shared/utils/types';
import { shareStatus } from './share';
import styles from './styles.module.scss';
import { ReactComponent as ShareIcon } from '../../../assets/icons/share.svg';
import { loadIsMobileFromLocalStorage } from '../../../shared/utils/localStorage';

interface ShareButtonProps {
  word: string;
  wordIndex: number;
  evaluations: WordStatus[];
  currentStreak: number;
  isWon: boolean;
  modeSettings: GameModesEnum;
  onShare: () => void;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  evaluations,
  word,
  wordIndex,
  currentStreak,
  isWon,
  modeSettings,
  onShare
}: ShareButtonProps) => (
  <button
    className={styles.shareButton}
    type="button"
    aria-label="share-button"
    onClick={() => {
      shareStatus({
        word,
        wordIndex,
        evaluations,
        currentStreak,
        isWon,
        modeSettings
      });
      const isMobile = loadIsMobileFromLocalStorage();
      if (!isMobile) onShare();
    }}
  >
    Share
    <ShareIcon className={styles.shareIcon} />
  </button>
);

export default ShareButton;
