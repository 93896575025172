import React, { useRef, useState } from 'react';
import {
  keys,
  loadDialogShownFromLocalStorage,
  setDialogShownInLocalStorage
} from 'shared/utils/localStorage';
import SharedGameDialog from '../SharedGameDialog/SharedGameDialog';
import {
  sendPlayAgainEvent,
  sendSharedEvent
} from '../../../shared/utils/gtmUtils';
import { Snackbar } from '../..';
import { getWordNumber } from '../../../gameLogic/words/wordsUtils';
import {
  GameModesEnum,
  GameState,
  GameStatistics,
  GameStatusesEnum,
  ShowHandle
} from '../../../shared/utils/types';
import { ShareButton } from '../../Buttons';
import PlayAgain from '../../PlayAgain/PlayAgain';
import Dialog from '../BaseDialog/BaseDialog';
import styles from './styles.module.scss';

interface StatsDialogProps {
  onClose: () => void;
  gameStats: GameStatistics;
  mode: GameModesEnum;
  gameState: GameState;
  handlePlayAgain: () => void;
  numOfGames: number;
  lastGameDate: Date;
}

function getWinPerct(gamesPlayed: number, gamesWon: number): number {
  return gamesWon > 0 ? Math.round((gamesWon / gamesPlayed) * 100) : 0;
}

const StatsDialog: React.FC<StatsDialogProps> = ({
  onClose,
  gameStats,
  mode,
  gameState,
  handlePlayAgain,
  numOfGames,
  lastGameDate
}: StatsDialogProps) => {
  const [sharedGameDialogShown, setSharedGameDialogShown] = useState<boolean>(
    loadDialogShownFromLocalStorage(keys.sharedGameDialogKey)
  );

  const shareShackbarRef = useRef<ShowHandle>(null);

  const handleSharedGameDialogClose = (): void => {
    setSharedGameDialogShown(true);
    setDialogShownInLocalStorage(keys.sharedGameDialogKey);
  };

  const handlePlayAgainClicked = (): void => {
    sendPlayAgainEvent();
    handlePlayAgain();
  };

  const handeShareClicked = (): void => {
    shareShackbarRef.current?.show();
    sendSharedEvent(getWordNumber(gameState.solution, mode));
  };

  return (
    <>
      <Dialog
        dialogChildrenStyles={`${
          sharedGameDialogShown ? styles.statsChildrenDialog : styles.statsChildrenDialog
        }`}
        dialogContentStyles={styles.statsDialog}
        isTextUppercase
        title="Statistics"
        onClose={onClose}
      >
        <div className={styles.subtitle}>{`(${mode.toLocaleLowerCase()})`}</div>
        <div className={styles.statsContainer}>
          <div className={styles.statsItem}>
            {gameStats.gamesPlayed}
            <div>Played</div>
          </div>
          <div className={styles.statsItem}>
            {getWinPerct(gameStats.gamesPlayed, gameStats.gamesWon)}
            <div>Win %</div>
          </div>
          <div className={styles.statsItem}>
            {gameStats.currentStreak}
            <div>
              Current
              <br />
              Streak
            </div>
          </div>
          <div className={styles.statsItem}>
            {gameStats.longestStreak}
            <div>
              Longest
              <br />
              Streak
            </div>
          </div>
          <div className={styles.statsItem}>
            {gameStats.averageGuesses}
            <div>
              Average
              <br />
              Guesses
            </div>
          </div>
          <div className={styles.statsItem}>
            {gameStats.fewestGuesses}
            <div>
              Fewest
              <br />
              Guesses
            </div>
          </div>
        </div>
        {gameState.gameStatus !== GameStatusesEnum.IN_PROGRESS && (
          <div className={styles.buttonsContainer}>
            <PlayAgain
              onClick={handlePlayAgainClicked}
              numOfGames={numOfGames}
              lastGameDate={lastGameDate}
            />
            <ShareButton
              isWon={gameState.gameStatus === GameStatusesEnum.WON}
              currentStreak={gameStats.currentStreak}
              word={gameState.solution}
              evaluations={gameState.evaluations}
              modeSettings={mode}
              wordIndex={getWordNumber(gameState.solution, mode)}
              onShare={handeShareClicked}
            />
          </div>
        )}
        {gameState.gameStatus !== GameStatusesEnum.IN_PROGRESS && !sharedGameDialogShown && (
          <SharedGameDialog onClose={handleSharedGameDialogClose} />
        )}
      </Dialog>
      <Snackbar ref={shareShackbarRef} timeout={1000} message="Copied results to clipboard" />
    </>
  );
};
export default StatsDialog;
