/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import styles from './styles.module.scss';

interface SharedGameDialogProps {
  onClose: () => void;
}

const SharedGameDialog: React.FC<SharedGameDialogProps> = ({
  onClose
}: SharedGameDialogProps) => (
  <div
    className={styles.dialogContent}
    onClick={(e): void => e.stopPropagation()}
  >
    <div className={styles.dialogContentWrapper}>
      <div className={styles.dialogChildren}>
        Unlimited play via shared game links.
      </div>
      <button
        type="button"
        className={` ${styles.closeButton}`}
        onClick={onClose}
      >
        <CloseIcon fontSize="small" className={styles.closeIcon} />
      </button>
    </div>
  </div>
);

export default SharedGameDialog;
