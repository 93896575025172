import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { GameModesEnum } from '../../shared/utils/types';
import { setGameToInProgress } from '../../gameLogic/state';
import { getModeFromWord, isWord } from '../../gameLogic/words/wordsUtils';
import GamePage from '../GamePage';

const SharedGamePage: React.FC = () => {
  const navigate = useNavigate();
  const { encodedWord } = useParams();

  const [decodedWord, setDecodedWord] = useState<string>('');
  const [mode, setMode] = useState<GameModesEnum>(GameModesEnum.STANDARD);

  useEffect(() => {
    try {
      const currDecodedWord = window.atob(encodedWord || '');
      const currMode = getModeFromWord(currDecodedWord);

      setDecodedWord(currDecodedWord);
      setMode(currMode);

      if (!isWord(currDecodedWord, currMode)) {
        navigate('/');
      }

      setGameToInProgress(currMode);
    } catch {
      navigate('/');
    }
  }, [decodedWord, encodedWord, mode, navigate]);

  if (!decodedWord || !mode) return null;

  return <GamePage mode={mode} sharedWord={decodedWord} />;
};

export default SharedGamePage;
