/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useState, useEffect, useMemo } from 'react';
import ThemeContext, { initialThemeState } from './ThemeContext';
import styles from './styles.module.scss';

interface ThemeProviderProps {
  children: React.ReactNode;
}

const ThemeProvider: React.FC<ThemeProviderProps> = ({
  children
}: ThemeProviderProps) => {
  const [theme, setTheme] = useState<string>(initialThemeState.theme);

  const { localStorage } = window;

  useEffect(() => {
    const savedThemeLocal = localStorage.getItem('globalTheme');

    if (savedThemeLocal) {
      setTheme(savedThemeLocal);
    }
  }, [localStorage]);

  useEffect(() => {
    localStorage.setItem('globalTheme', theme);
  }, [localStorage, theme]);

  const value = useMemo(
    () => ({
      theme,
      setTheme
    }),
    [theme]
  );

  return (
    // @ts-ignore
    <ThemeContext.Provider value={value}>
      <div className={`theme--${theme} ${styles.theme}`}>{children}</div>
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
