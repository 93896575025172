import React, { useLayoutEffect, useRef, useState } from 'react';
import Loader from '../Loader/Loader';
import { GameConfig, WordStatus } from '../../shared/utils/types';
import { CompletedRow, CurrentRow, EmptyRow } from './components';
import styles from './styles.module.scss';

interface BoardProps {
  modeSettings: GameConfig;
  mode: string;
  currentGuess: string[];
  guesses: string[];
  evaluations: WordStatus[];
  animateOnInvalidWord: boolean;
  currentState: string;
}

const Board: React.FC<BoardProps> = ({
  guesses,
  currentGuess,
  modeSettings,
  mode,
  evaluations,
  animateOnInvalidWord,
  currentState
}: BoardProps) => {
  const boardContainer = useRef<HTMLHeadingElement>(null);

  const [boardStyles, setBoardStyles] = useState<{
    height: number;
    width: number;
  }>({
    height: window.innerHeight - 250,
    width: window.innerWidth < 500 ? window.innerWidth : 500
  });

  const emptyRows =
    guesses.length < modeSettings.MAX_CHALLENGES - 1
      ? Array.from(Array(modeSettings.MAX_CHALLENGES - 1 - guesses.length))
      : [];

  useLayoutEffect(() => {
    const resizeListener = (): void => {
      const width = Math.min(
        Math.floor(
          (boardContainer.current ? boardContainer.current.clientHeight : 1) *
            (modeSettings.MAX_WORD_LENGTH / modeSettings.MAX_CHALLENGES)
        ),
        250
      );

      const height =
        modeSettings.MAX_CHALLENGES *
        Math.floor(width / modeSettings.MAX_WORD_LENGTH);

      setBoardStyles({ height, width });
    };

    resizeListener();

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div ref={boardContainer} className={styles.boardContainer}>
      {!boardStyles ? (
        <Loader />
      ) : (
        <div
          className={styles.board}
          style={{
            gridTemplateRows: `repeat(${modeSettings.MAX_CHALLENGES}, 1fr)`,
            width: `${boardStyles?.width}px`,
            height: `${boardStyles?.height}px`
          }}
        >
          {guesses.map((guess: string, i) => (
            <CompletedRow
              key={i}
              guess={guess}
              mode={mode}
              modeSettings={modeSettings}
              status={evaluations[i]}
              currentState={currentState}
              isLastRow={i === guesses.length - 1}
            />
          ))}
          {guesses.length < modeSettings.MAX_CHALLENGES && (
            <CurrentRow
              modeSettings={modeSettings}
              guess={currentGuess}
              currentState={currentState}
              animateOnInvalidWord={animateOnInvalidWord}
            />
          )}
          {emptyRows.map((_, i) => (
            <EmptyRow modeSettings={modeSettings} key={i} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Board;
