/* eslint-disable max-len */
/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import styles from './styles.module.scss';

interface KeyboardDialogProps {
  onClose: () => void;
}

const KeyboardDialog: React.FC<KeyboardDialogProps> = ({
  onClose
}: KeyboardDialogProps) => (
  <div className={styles.dialogContainer}>
    <div
      className={styles.dialogContent}
      onClick={(e): void => e.stopPropagation()}
    >
      <div className={styles.dialogContentWrapper}>
        <div className={styles.svgContainer}>
          <img
            src="thinking_emoji.png"
            alt="Thinking emoji icon"
            className={styles.emoji}
          />
        </div>
        <div className={styles.dialogChildren}>
          <span className={styles.dialogTitle}>Worderly Pro Tip:</span><br />
          Press and hold to darken letters you think are not in the word.
        </div>
        <button
          type="button"
          className={` ${styles.closeButton}`}
          onClick={onClose}
        >
          <CloseIcon fontSize="small" className={styles.closeIcon} />
        </button>
      </div>
    </div>
  </div>
);

export default KeyboardDialog;
