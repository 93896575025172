/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import styles from './styles.module.scss';

interface BaseDialogProps {
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
  dialogContentStyles?: string;
  dialogStyles?: string;
  isTextUppercase?: boolean;
  dialogChildrenStyles?: string;
  closeButtonStyles?: string;
  headerStyles?: string;
}

const BaseDialog: React.FC<BaseDialogProps> = ({
  dialogStyles = '',
  dialogContentStyles = '',
  closeButtonStyles = '',
  headerStyles = '',
  onClose,
  title = '',
  children,
  isTextUppercase = false,
  dialogChildrenStyles = ''
}: BaseDialogProps) => (
  <div
    role="dialog"
    className={`${dialogStyles} ${styles.dialog}`}
    onClick={onClose}
  >
    <div
      className={`${dialogContentStyles} ${styles.dialogContent}`}
      onClick={(e): void => e.stopPropagation()}
    >
      <div className={styles.dialogContentWrapper}>
        <header className={`${headerStyles} ${styles.header}`}>
          <h3
            className={
              isTextUppercase ? styles.uppercaseHeader : styles.initialHeader
            }
          >
            {title}
          </h3>
          <button
            type="button"
            className={`${closeButtonStyles} ${styles.closeButton}`}
            onClick={onClose}
            // onKeyUp={() => console.log('implement key up')}
          >
            <CloseIcon fontSize="small" className={styles.closeIcon} />
          </button>
        </header>
        <div className={`${dialogChildrenStyles} ${styles.dialogChildren}`}>
          {children}
        </div>
      </div>
    </div>
  </div>
);

export default BaseDialog;
