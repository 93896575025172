/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/anchor-is-valid */
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import ThemeContext from 'contexts/ThemeContext';
import React, { useContext } from 'react';
import Dialog from '../BaseDialog/BaseDialog';
import styles from './styles.module.scss';
import packageJson from '../../../../package.json';

interface SettingsDialogProps {
  onClose: () => void;
}

const SettingsDialog: React.FC<SettingsDialogProps> = ({
  onClose
}: SettingsDialogProps) => {
  const { theme, setTheme } = useContext(ThemeContext);
  return (
    <Dialog
      dialogStyles={styles.settingsDialog}
      dialogContentStyles={styles.settingsDialog}
      isTextUppercase
      title="Settings"
      onClose={onClose}
    >
      <div className={styles.settingsWrapper}>
        <div>
          <div className={styles.colorBlindSwitchWrapper}>
            <div>
              <p className={styles.colorBlindSwitchLabel}>Color Blind Mode</p>
              <p className={styles.colorBlindSwitchDescription}>
                High contrast colors
              </p>
            </div>
            <ToggleSwitch
              id="colorBlindModeSwitch"
              name="color blind mode switch"
              checked={theme === 'colorBlind'}
              onChange={(checked): null =>
                setTheme(checked ? 'colorBlind' : 'default')
              }
              className={styles.switch}
              size="normal"
            />
          </div>
          <div className={styles.feedback}>
            <p>Feedback</p>
            <span className={styles.links}>
              <a className={styles.link} href="mailto:info@worder.ly">
                Email
              </a>
              |
              <a className={styles.link} href="https://twitter.com/worderly_game">
                Twitter
              </a>
              |
              <a className={styles.link} href="https://www.facebook.com/groups/worderly">
                Facebook
              </a>
            </span>
          </div>
        </div>
        <footer className={styles.settingsFooter}>
          <span>
            <a className={styles.privacyPolicy} href="https://www.iubenda.com/privacy-policy/69807737">
              Privacy policy
            </a>

            <p className={styles.copyright}>
              ©Senfino LLC 2022
            </p>
          </span>
          <p className={styles.version}>v{packageJson.version}</p>
        </footer>
      </div>
    </Dialog>
  );
};

export default SettingsDialog;
