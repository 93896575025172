import React from 'react';
import styles from './styles.module.scss';
import { ReactComponent as SettingsIcon } from '../../../../assets/icons/settings.svg';
import colors from '../../../../shared/scss/colors.module.scss';

interface SettingsButtonProps {
  onClick: (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const SettingsButton: React.FC<SettingsButtonProps> = ({
  onClick
}: SettingsButtonProps) => (
  <button
    className={styles.settingsButton}
    type="button"
    aria-label="help"
    onClick={onClick}
  >
    <SettingsIcon fill={colors.darkgrey} />
  </button>
);

export default SettingsButton;
