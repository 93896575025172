import { GameStatusesEnum } from '../shared/utils/types';
import {
  GAME_CONFIG,
  NUMBER_OF_GAMES_PER_DAY
} from '../shared/utils/constants';
import { IGameContext, IGuessedEvent, ISharedWordEvent } from './types';

export function wonGuard(context: IGameContext, event: IGuessedEvent): boolean {
  const { gameState } = context.games[context.mode];
  return gameState.solution === event.word;
}

export function failedGuard(
  context: IGameContext,
  event: IGuessedEvent
): boolean {
  const { gameState } = context.games[context.mode];

  return (
    gameState.solution !== event.word &&
    GAME_CONFIG[context.mode].MAX_CHALLENGES === gameState.guesses.length + 1
  );
}

export function guessedGuard(
  context: IGameContext,
  event: IGuessedEvent
): boolean {
  return !wonGuard(context, event) && !failedGuard(context, event);
}

// Play shared game guards

export function playAgainDisplayOldWonGameGuard(
  context: IGameContext,
  event: ISharedWordEvent
): boolean {
  return event.oldGame && event.oldGame.gameStatus === GameStatusesEnum.WON;
}

export function playAgainDisplayOldFailedGameGuard(
  context: IGameContext,
  event: ISharedWordEvent
): boolean {
  return event.oldGame && event.oldGame.gameStatus === GameStatusesEnum.FAILED;
}

export function playAgainGuard(
  context: IGameContext,
  event: ISharedWordEvent
): boolean {
  return (
    (new Date(context.lastGameDate).toDateString() !==
      new Date().toDateString() ||
    context.numOfGames < NUMBER_OF_GAMES_PER_DAY) &&
    (!event.oldGame || event.oldGame.gameStatus === GameStatusesEnum.IN_PROGRESS)
  );
}

export function playAgainSharedWordGuard(
  context: IGameContext,
  event: ISharedWordEvent
): boolean {
  return !event.oldGame;
}

// Block game
export function blockGameGuard(context: IGameContext): boolean {
  return context.numOfGames >= NUMBER_OF_GAMES_PER_DAY;
}
