import { GAME_CONFIG } from '../../shared/utils/constants';
import { GameModesEnum } from '../../shared/utils/types';
import { WordListType } from '../types';

export function getWordList(mode: GameModesEnum, listType: WordListType): string[] {
  const config = GAME_CONFIG[mode];
  return listType === WordListType.GAME_LIST ? config.GAME_WORDS : config.FULL_WORDS_LIST;
}

export function getRandomWord(mode: GameModesEnum): string {
  const currentList = getWordList(mode, WordListType.GAME_LIST);
  return currentList[
    Math.floor(Math.random() * currentList.length)
  ].toUpperCase();
}

function binarySearch(key: string, words: string[]): number {
  let low = 0;
  let high = words.length - 1;
  while (low <= high) {
    const mid = Math.floor((low + high) / 2);
    const res = key.localeCompare(words[mid]);
    if (res === 0) {
      return mid;
    }
    if (res > 0) low = mid + 1;
    else high = mid - 1;
  }
  return -1;
}

export function isWord(word: string, mode: GameModesEnum): boolean {
  const currentList = getWordList(mode, WordListType.FULL_LIST);
  return binarySearch(word.toLocaleLowerCase(), currentList) >= 0;
}

export function getWordNumber(word: string, mode: GameModesEnum): number {
  const currentList = getWordList(mode, WordListType.GAME_LIST);
  return currentList.indexOf(word.toLocaleLowerCase());
}

export function getModeFromWord(word: string): GameModesEnum {
  const gameModes = Object.values(GameModesEnum);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return gameModes.find((gm) => GAME_CONFIG[gm].MAX_WORD_LENGTH === word.length)!;
}
