/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';

// @ts-ignore
export const initialThemeState = {
  theme: 'default',
  setTheme: (_args: string) => null
};

const ThemeContext = React.createContext(initialThemeState);
export default ThemeContext;
