import React from 'react';
import styles from './styles.module.scss';

interface KeyProps {
  children?: React.ReactNode;
  value: string;
  width?: number;
  isAbsent?: boolean;
  onClick?: (_value: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const Key: React.FC<KeyProps> = ({
  children,
  width = 45,
  value,
  onClick,
  isAbsent,
  ...props
}: KeyProps) => {
  const inlineStyles = {
    width: `${width}px`,
    height: '58px',
    cursor: 'pointer'
  };

  return (
    <button
      {...props}
      type="button"
      id={value}
      alia-label={`${value}-keyboard-button`}
      style={inlineStyles}
      onClick={onClick}
      className={`${styles.key} ${isAbsent ? styles.absent : ''}`}
    >
      {children || value}
    </button>
  );
};

export default Key;
