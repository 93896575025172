/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react';
import { NUMBER_OF_GAMES_PER_DAY } from '../../shared/utils/constants';
import { PrimaryButton } from '../Buttons';
import { Countdown } from './components';
import { calculateTimeTillMidnight } from './countdown';

interface PlayAgainProps {
  onClick: () => void;
  numOfGames: number;
  lastGameDate: Date;
}

const PlayAgain: React.FC<PlayAgainProps> = ({
  onClick,
  numOfGames,
  lastGameDate
}: PlayAgainProps) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeTillMidnight());

  useEffect(() => {
    const timeout = setTimeout(() => {
      setTimeLeft(calculateTimeTillMidnight());
    }, 1000);

    return () => clearTimeout(timeout);
  });
  return timeLeft &&
    numOfGames >= NUMBER_OF_GAMES_PER_DAY &&
    new Date(lastGameDate).toDateString() === new Date().toDateString() ? (
    <Countdown timeLeft={timeLeft} />
  ) : (
    <PrimaryButton text="Play again" onClick={onClick} />
  );
};

export default PlayAgain;
