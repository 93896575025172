import React from 'react';
import styles from './styles.module.scss';

interface PrimaryButtonProps {
  text: string;
  onClick: (_e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  text,
  onClick,
}: PrimaryButtonProps) => (
  <button
    className={styles.primaryButton}
    type="button"
    aria-label={`${text}-button`}
    onClick={onClick}
  >
    {text}
  </button>
);

export default PrimaryButton;
