import { GameModesEnum, WordStatus } from '../../../shared/utils/types';
import {
  EVALUATION_STATUSES,
  GAME_CONFIG,
  GAME_TITLE_LOWERCASE
} from '../../../shared/utils/constants';
import { loadIsMobileFromLocalStorage } from '../../../shared/utils/localStorage';

export const generateEvaluationsGrid = (evaluations: WordStatus[]): string =>
  evaluations
    .map((status) => {
      let res = Object.keys(status)
        .map((key) => Array(status[key])
          .fill(EVALUATION_STATUSES.PRESENT === key ? '🟡' : '🟢')
          .map((item) => item)
          .join('')
        )
        .join('');

      if (!res) res = '❌';

      return res;
    }
    )
    .join('\n');

export const shareStatus = ({
  word,
  wordIndex,
  evaluations,
  currentStreak,
  modeSettings,
  isWon
}: {
  word: string;
  wordIndex: number;
  evaluations: WordStatus[];
  currentStreak: number;
  isWon: boolean;
  modeSettings: GameModesEnum;
}): void => {
  const textToShare = `${GAME_TITLE_LOWERCASE} #${wordIndex}. ${
    isWon ? evaluations.length : 'X'
  }/${
    GAME_CONFIG[modeSettings].MAX_CHALLENGES
  } Current streak: ${currentStreak}\n\n${generateEvaluationsGrid(
    evaluations
  )}\n\nPlay this word. ${window.location.origin.toString()}/${window.btoa(
    word
  )} #PlayMyWorderly`;

  const isMobile = loadIsMobileFromLocalStorage();
  if (navigator.share && isMobile) {
    navigator.share({
      text: textToShare
    });
  } else {
    navigator.clipboard.writeText(textToShare);
  }
};
