import React from 'react';
import { GameConfig, GameStatusesEnum } from '../../../../../shared/utils/types';
import Tile from '../../Tile/Tile';
import styles from './styles.module.scss';

interface CurrentRowProps {
  guess: string[];
  modeSettings: GameConfig;
  currentState: string;
  animateOnInvalidWord: boolean;
}

const CurrentRow: React.FC<CurrentRowProps> = ({
  guess,
  modeSettings,
  animateOnInvalidWord,
  currentState
}: CurrentRowProps) => {
  const emptyTiles = Array.from(
    Array(modeSettings.MAX_WORD_LENGTH - guess.length)
  );

  return (
    <div
      className={`${styles.currentRow} ${
        animateOnInvalidWord ? styles.shake : ''
      }`}
      style={{
        gridTemplateColumns: `repeat(${modeSettings.MAX_WORD_LENGTH}, 1fr)`
      }}
    >
      {guess.map((letter, i) => (
        <Tile shouldActivateAnimation key={i} value={letter} />
      ))}
      {emptyTiles.map((_, i) => (
        <Tile
          shouldDisplayBadge={
            currentState === GameStatusesEnum.IN_PROGRESS &&
            i === 0 &&
            !guess.length
          }
          key={i}
        />
      ))}
    </div>
  );
};

export default CurrentRow;
