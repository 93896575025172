export const keys = {
  helpDialogShownKey: 'helpDialogShown',
  keyboardHoldDialogKey: 'keyboardHoldDialogShown',
  sharedGameDialogKey: 'sharedGameDialogShown',
  isMobile: 'isMobile'
};

export const setDialogShownInLocalStorage = (key: string): void => {
  localStorage.setItem(key, JSON.stringify(true));
};

export const loadDialogShownFromLocalStorage = (key: string): boolean => {
  const state = localStorage.getItem(key);
  return state ? (JSON.parse(state) as boolean) : false;
};

export const setIsMobileInLocalStorage = (value: boolean): void => {
  localStorage.setItem(keys.isMobile, JSON.stringify(value));
};

export const loadIsMobileFromLocalStorage = (): boolean => {
  const state = localStorage.getItem(keys.isMobile);
  return state ? (JSON.parse(state) as boolean) : false;
};
