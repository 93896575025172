import { KEYBOARD } from '../shared/utils/constants';
import { GameModesEnum, GameStatusesEnum, KeyboardState } from '../shared/utils/types';
import { IGameContext } from './types';
import { getRandomWord } from './words/wordsUtils';

const savedStateKey = 'state';

const defaultkeyboardState: KeyboardState = Object.assign(
  {},
  ...Object.values(KEYBOARD.LETTERS).map((keys: string[]) =>
    keys.reduce((o, key: string) => ({ ...o, [key]: '' }), {})
  )
);

export const INITIAL_STATE: IGameContext = {
  keyboardState: defaultkeyboardState,
  numOfGames: 0,
  lastGameDate: new Date(),
  mode: GameModesEnum.STANDARD,
  games: {
    standard: {
      gameState: {
        guesses: [],
        evaluations: [],
        gameStatus: GameStatusesEnum.IN_PROGRESS,
        solution: ''
      },
      gameStatistics: {
        history: [],
        gamesPlayed: 0,
        gamesWon: 0,
        currentStreak: 0,
        longestStreak: 0,
        averageGuesses: 0,
        fewestGuesses: 0,
        winningGuesses: []
      }
    },
    expert: {
      gameState: {
        guesses: [],
        evaluations: [],
        gameStatus: GameStatusesEnum.IN_PROGRESS,
        solution: ''
      },
      gameStatistics: {
        history: [],
        gamesPlayed: 0,
        gamesWon: 0,
        currentStreak: 0,
        longestStreak: 0,
        averageGuesses: 0,
        fewestGuesses: 0,
        winningGuesses: []
      }
    }
  }
};

export function saveState(context: IGameContext): void {
  localStorage.setItem(savedStateKey, JSON.stringify(context));
}

export function getInitialState(mode: GameModesEnum): IGameContext {
  const state = localStorage.getItem(savedStateKey);

  if (state) {
    return JSON.parse(state) as IGameContext;
  }

  const initialState = { ...INITIAL_STATE, mode };

  const gameModes = Object.values(GameModesEnum);
  gameModes.forEach((el) => {
    initialState.games[el].gameState.solution = getRandomWord(el);
  });

  return { ...INITIAL_STATE, mode };
}

export function setGameToInProgress(mode: GameModesEnum):void {
  const context = getInitialState(mode);
  const { gameState } = context.games[mode];

  gameState.gameStatus = GameStatusesEnum.IN_PROGRESS;
  saveState(context);
}
