/* eslint-disable no-unused-vars */
import { GameModesEnum, GameState, GameStatistics } from '../shared/utils/types';

export interface ICEvent {
  type: string;
}

export interface IGuessedEvent extends Event {
  word: string;
}

export interface IToggleKeyEvent extends Event {
  key: string;
}

export interface ISharedWordEvent extends Event {
  word: string;
  oldGame: GameState;
}

type GameModeData = {
  gameState: GameState;
  gameStatistics: GameStatistics;
};
export interface IGameContext {
  mode: GameModesEnum;
  numOfGames: number;
  lastGameDate: Date;
  keyboardState: { [key: string]: string };
  games: {
    [key in GameModesEnum]: GameModeData;
  }
}

export enum WordListType {
  FULL_LIST,
  GAME_LIST
}
