import { GameModesEnum, GameState, GameStatusesEnum } from '../shared/utils/types';
/* eslint-disable indent */
import { getGuessStatus } from '../shared/utils/evaluation';
import {
  ABSENT_STATUS,
} from '../shared/utils/constants';
import { saveState } from './state';
import {
  IGameContext,
  IGuessedEvent,
  IToggleKeyEvent,
  ISharedWordEvent
} from './types';
import { getRandomWord, getWordNumber } from './words/wordsUtils';
import { sendGameGuessEvent, sendGameOverEvent } from '../shared/utils/gtmUtils';

function isNotSharedGame():boolean {
  return document.location && (document.location.pathname === '/s' || document.location.pathname === '/e');
}

export function guessedReducer(
  context: IGameContext,
  event: IGuessedEvent
): IGameContext {
  const newState = { ...context };
  const { gameState } = newState.games[newState.mode];

  sendGameGuessEvent(getWordNumber(gameState.solution, context.mode),
    context.mode,
gameState.evaluations.length);

  gameState.guesses.push(event.word);
  gameState.evaluations.push(getGuessStatus(event.word, gameState.solution));
  saveState(newState);
  return newState;
}

export function wonReducer(
  context: IGameContext,
  event: IGuessedEvent
): IGameContext {
  if (isNotSharedGame()) {
  context.numOfGames++;
  }
  context.keyboardState = {};
  context.lastGameDate = new Date();

  const { gameState, gameStatistics } = context.games[context.mode];

  gameState.guesses.push(event.word);
  gameState.evaluations.push(getGuessStatus(event.word, gameState.solution));
  gameState.gameStatus = GameStatusesEnum.WON;

  sendGameOverEvent(getWordNumber(gameState.solution, context.mode),
    context.mode,
1);

  gameStatistics.gamesPlayed++;
  gameStatistics.gamesWon++;
  gameStatistics.currentStreak++;
  if (gameStatistics.currentStreak > gameStatistics.longestStreak) {
    gameStatistics.longestStreak = gameStatistics.currentStreak;
  }

  const numOfGuesses = gameState.guesses.length;
  if (
    numOfGuesses < gameStatistics.fewestGuesses ||
    gameStatistics.fewestGuesses === 0
  ) {
    gameStatistics.fewestGuesses = numOfGuesses;
  }

  gameStatistics.winningGuesses.push(numOfGuesses);
  gameStatistics.averageGuesses =
    gameStatistics.winningGuesses.length > 0
      ? Math.round(
          (gameStatistics.winningGuesses.reduce((prev, cur) => cur + prev, 0) /
            gameStatistics.winningGuesses.length) * 10
        ) / 10
      : 0;
  gameStatistics.history.push(gameState);

  saveState(context);
  return { ...context };
}

export function failedReducer(
  context: IGameContext,
  event: IGuessedEvent
): IGameContext {
  if (isNotSharedGame()) {
  context.numOfGames++;
  }

  context.keyboardState = {};
  context.lastGameDate = new Date();

  const { gameState, gameStatistics } = context.games[context.mode];

  gameState.guesses.push(event.word);
  gameState.evaluations.push(getGuessStatus(event.word, gameState.solution));
  gameState.gameStatus = GameStatusesEnum.FAILED;

  sendGameOverEvent(getWordNumber(gameState.solution, context.mode),
    context.mode,
0);

  // Updating stats
  gameStatistics.gamesPlayed++;
  gameStatistics.currentStreak = 0;
  gameStatistics.history.push(gameState);

  saveState(context);
  return { ...context };
}

export function toggleKeyReducer(
  context: IGameContext,
  event: IToggleKeyEvent
): IGameContext {
  const togggledStatus =
    context.keyboardState[event.key] === ABSENT_STATUS ? '' : ABSENT_STATUS;

  const newKeyboardState = {
    ...context.keyboardState,
    [event.key]: togggledStatus
  };

  context.keyboardState = newKeyboardState;

  saveState(context);
  return { ...context };
}

export function playAgainReducer(context: IGameContext): IGameContext {
  const newState = { ...context };
  newState.keyboardState = {};

  const { games } = newState;

  function getResetedGameStatus(
    gameState: GameState,
    mode: GameModesEnum
  ): GameState {
    const resetedState = { ...gameState };
    resetedState.gameStatus = GameStatusesEnum.IN_PROGRESS;
    resetedState.solution = getRandomWord(mode);
    resetedState.guesses = [];
    resetedState.evaluations = [];
    return resetedState;
  }

  const gameModes = Object.values(GameModesEnum);
  gameModes.forEach((el) => {
    games[el].gameState = getResetedGameStatus(games[el].gameState, el);
  });

  if (
    new Date(newState.lastGameDate).toDateString() !== new Date().toDateString()
  ) {
    newState.numOfGames = 0;
  }
  newState.lastGameDate = new Date();

  saveState(newState);
  return newState;
}

export function playAgainSharedWord(
  context: IGameContext,
  event: ISharedWordEvent
): IGameContext {
  context.keyboardState = {};
  const { gameState, gameStatistics } = context.games[context.mode];

  const alreadyPlayedGames = gameStatistics.history.find(
    (e) => e.solution === event.word
  );

  if (alreadyPlayedGames) {
    gameState.gameStatus = alreadyPlayedGames.gameStatus;
    gameState.solution = alreadyPlayedGames.solution;
    gameState.evaluations = alreadyPlayedGames.evaluations;
    gameState.guesses = alreadyPlayedGames.guesses;
  } else {
    gameState.gameStatus = GameStatusesEnum.IN_PROGRESS;
    gameState.solution = event.word;
    gameState.evaluations = [];
    gameState.guesses = [];
  }

  return { ...context };
}

export function oldGameReducer(
  context: IGameContext,
  event: ISharedWordEvent
): IGameContext {
  const { gameState } = context.games[context.mode];

  gameState.gameStatus = event.oldGame.gameStatus;
  gameState.solution = event.oldGame.solution;
  gameState.evaluations = event.oldGame.evaluations;
  gameState.guesses = event.oldGame.guesses;
  return { ...context };
}

export function blockGameReducer(context: IGameContext): IGameContext {
  const { gameState } = context.games[context.mode];

  gameState.gameStatus = GameStatusesEnum.FAILED;
  return { ...context };
}
