const beginnerWordList = [
  'able',
  'acid',
  'acre',
  'aged',
  'aide',
  'ally',
  'also',
  'amid',
  'area',
  'army',
  'aunt',
  'auto',
  'away',
  'baby',
  'back',
  'bake',
  'ball',
  'band',
  'bang',
  'bank',
  'bare',
  'barn',
  'base',
  'bath',
  'beam',
  'bean',
  'bear',
  'beat',
  'beef',
  'been',
  'beer',
  'bell',
  'belt',
  'bend',
  'best',
  'bias',
  'bike',
  'bill',
  'bind',
  'bird',
  'bite',
  'blog',
  'blow',
  'blue',
  'boat',
  'body',
  'boil',
  'bold',
  'bomb',
  'bond',
  'bone',
  'book',
  'boom',
  'boot',
  'born',
  'boss',
  'both',
  'bowl',
  'buck',
  'bulk',
  'bull',
  'burn',
  'bury',
  'bush',
  'busy',
  'cafe',
  'cage',
  'cake',
  'call',
  'calm',
  'came',
  'camp',
  'card',
  'care',
  'cart',
  'case',
  'cash',
  'cast',
  'cave',
  'cell',
  'cent',
  'chat',
  'chef',
  'chew',
  'chin',
  'chip',
  'chop',
  'cite',
  'city',
  'clay',
  'clip',
  'club',
  'clue',
  'coal',
  'coat',
  'code',
  'coin',
  'cold',
  'come',
  'cook',
  'cool',
  'cope',
  'copy',
  'cord',
  'core',
  'corn',
  'cost',
  'crew',
  'crop',
  'cure',
  'cute',
  'dare',
  'dark',
  'data',
  'date',
  'dawn',
  'dead',
  'deal',
  'dean',
  'dear',
  'debt',
  'deck',
  'deem',
  'deep',
  'deer',
  'deny',
  'desk',
  'dial',
  'diet',
  'dirt',
  'disc',
  'dish',
  'disk',
  'does',
  'doll',
  'done',
  'door',
  'dose',
  'down',
  'drag',
  'draw',
  'drew',
  'drop',
  'drug',
  'drum',
  'dual',
  'duck',
  'dude',
  'duke',
  'dumb',
  'dump',
  'dust',
  'duty',
  'each',
  'earn',
  'ease',
  'east',
  'easy',
  'echo',
  'edge',
  'edit',
  'else',
  'even',
  'ever',
  'evil',
  'exam',
  'exit',
  'face',
  'fact',
  'fade',
  'fail',
  'fair',
  'fake',
  'fall',
  'fame',
  'farm',
  'fast',
  'fate',
  'fear',
  'feed',
  'feel',
  'feet',
  'fell',
  'felt',
  'file',
  'fill',
  'film',
  'find',
  'fine',
  'fire',
  'firm',
  'fish',
  'fist',
  'five',
  'flag',
  'flat',
  'flaw',
  'flee',
  'flip',
  'flow',
  'fold',
  'folk',
  'food',
  'fool',
  'foot',
  'ford',
  'fork',
  'form',
  'fort',
  'four',
  'free',
  'from',
  'fuel',
  'full',
  'fund',
  'gain',
  'game',
  'gang',
  'gasp',
  'gate',
  'gave',
  'gaze',
  'gear',
  'gene',
  'gift',
  'girl',
  'give',
  'glad',
  'goal',
  'goat',
  'goes',
  'gold',
  'golf',
  'gone',
  'good',
  'grab',
  'gram',
  'gray',
  'grew',
  'grey',
  'grid',
  'grip',
  'grow',
  'gulf',
  'hair',
  'half',
  'hall',
  'hand',
  'hang',
  'hard',
  'harm',
  'hate',
  'have',
  'head',
  'heal',
  'hear',
  'heat',
  'heel',
  'held',
  'help',
  'herb',
  'here',
  'hero',
  'hide',
  'high',
  'hill',
  'hint',
  'hire',
  'hold',
  'hole',
  'holy',
  'home',
  'hook',
  'hope',
  'horn',
  'host',
  'hour',
  'huge',
  'hung',
  'hunt',
  'hurt',
  'icon',
  'idea',
  'inch',
  'info',
  'into',
  'iron',
  'item',
  'jail',
  'jazz',
  'join',
  'joke',
  'jump',
  'junk',
  'jury',
  'just',
  'keen',
  'keep',
  'kent',
  'kept',
  'kick',
  'kill',
  'kind',
  'king',
  'kiss',
  'knee',
  'knew',
  'know',
  'lack',
  'lady',
  'laid',
  'lake',
  'lamp',
  'land',
  'lane',
  'last',
  'late',
  'lawn',
  'lazy',
  'lead',
  'leaf',
  'leak',
  'lean',
  'leap',
  'left',
  'lend',
  'lens',
  'less',
  'liar',
  'life',
  'lift',
  'like',
  'limb',
  'line',
  'link',
  'lion',
  'list',
  'live',
  'load',
  'loan',
  'lock',
  'logo',
  'long',
  'look',
  'loop',
  'lord',
  'lose',
  'loss',
  'lost',
  'loud',
  'love',
  'luck',
  'lung',
  'made',
  'mail',
  'main',
  'make',
  'male',
  'mall',
  'many',
  'mark',
  'mask',
  'mass',
  'mate',
  'math',
  'meal',
  'mean',
  'meat',
  'meet',
  'melt',
  'menu',
  'mere',
  'mess',
  'mild',
  'mile',
  'milk',
  'mill',
  'mind',
  'mine',
  'miss',
  'mode',
  'mood',
  'moon',
  'more',
  'most',
  'move',
  'much',
  'must',
  'myth',
  'nail',
  'name',
  'navy',
  'near',
  'neck',
  'need',
  'nest',
  'news',
  'next',
  'nice',
  'nick',
  'nine',
  'none',
  'noon',
  'norm',
  'nose',
  'note',
  'okay',
  'omit',
  'once',
  'only',
  'onto',
  'open',
  'oral',
  'oven',
  'over',
  'pace',
  'pack',
  'page',
  'paid',
  'pain',
  'pair',
  'pale',
  'palm',
  'pant',
  'park',
  'part',
  'pass',
  'past',
  'path',
  'peak',
  'peel',
  'peer',
  'pick',
  'pile',
  'pill',
  'pine',
  'pink',
  'pipe',
  'plan',
  'play',
  'plea',
  'plot',
  'plug',
  'plus',
  'poem',
  'poet',
  'pole',
  'poll',
  'pond',
  'pool',
  'poor',
  'pope',
  'pork',
  'port',
  'pose',
  'post',
  'pour',
  'pray',
  'pull',
  'pump',
  'pure',
  'push',
  'quit',
  'race',
  'rack',
  'rage',
  'rail',
  'rain',
  'rank',
  'rare',
  'rate',
  'read',
  'real',
  'rear',
  'rely',
  'rent',
  'rest',
  'rice',
  'rich',
  'ride',
  'ring',
  'riot',
  'rise',
  'risk',
  'road',
  'rock',
  'role',
  'roll',
  'roof',
  'room',
  'root',
  'rope',
  'rose',
  'rude',
  'ruin',
  'rule',
  'rush',
  'sack',
  'safe',
  'said',
  'sail',
  'sake',
  'sale',
  'salt',
  'same',
  'sand',
  'save',
  'scan',
  'seal',
  'seat',
  'seed',
  'seek',
  'seem',
  'seen',
  'self',
  'sell',
  'send',
  'sent',
  'ship',
  'shoe',
  'shop',
  'shot',
  'show',
  'shut',
  'sick',
  'side',
  'sigh',
  'sign',
  'silk',
  'sing',
  'sink',
  'site',
  'size',
  'skin',
  'skip',
  'slam',
  'slap',
  'slip',
  'slow',
  'snap',
  'snow',
  'soap',
  'sock',
  'soda',
  'soft',
  'soil',
  'sold',
  'sole',
  'solo',
  'some',
  'song',
  'soon',
  'sort',
  'soul',
  'soup',
  'spin',
  'spit',
  'spot',
  'star',
  'stay',
  'stem',
  'step',
  'stir',
  'stop',
  'such',
  'suit',
  'sure',
  'swim',
  'tail',
  'take',
  'tale',
  'talk',
  'tall',
  'tank',
  'tape',
  'task',
  'team',
  'tear',
  'teen',
  'tell',
  'tend',
  'tent',
  'term',
  'test',
  'text',
  'than',
  'that',
  'them',
  'then',
  'they',
  'thin',
  'this',
  'tide',
  'till',
  'time',
  'tiny',
  'tire',
  'told',
  'toll',
  'tone',
  'took',
  'tool',
  'toss',
  'tour',
  'town',
  'trap',
  'tree',
  'trip',
  'tube',
  'tuck',
  'tune',
  'turn',
  'twin',
  'type',
  'ugly',
  'unit',
  'upon',
  'urge',
  'used',
  'user',
  'vary',
  'vast',
  'very',
  'vice',
  'view',
  'vote',
  'wage',
  'wait',
  'wake',
  'walk',
  'wall',
  'want',
  'ward',
  'warm',
  'warn',
  'wash',
  'wave',
  'weak',
  'wear',
  'weed',
  'week',
  'well',
  'went',
  'were',
  'west',
  'what',
  'when',
  'whip',
  'whom',
  'wide',
  'wife',
  'wild',
  'will',
  'wind',
  'wine',
  'wing',
  'wipe',
  'wire',
  'wise',
  'wish',
  'with',
  'wolf',
  'wood',
  'word',
  'wore',
  'work',
  'wrap',
  'yard',
  'yeah',
  'year',
  'yell',
  'your',
  'zero',
  'zone',
  'true'
];

export default beginnerWordList;
