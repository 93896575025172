import React from 'react';
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import ThemeProvider from 'contexts/ThemeProvider';
import { GameModesEnum } from '../shared/utils/types';
import styles from './styles.module.scss';
import LandingPage from './LangingPage';
import GamePage from './GamePage';
import SharedGamePage from './SharedGamePage';

const App: React.FC = () => {
  if (navigator && navigator.userAgent && navigator.userAgent.match(/FBAV/i) &&
        !navigator.userAgent.includes('FBAN/MessengerLiteForiOS')) {
    return (
      <img
        src="fb_share.png"
        className={styles.fbImg}
        alt="Share though Facebook"
      />
    );
  }

  return (
    <div className={styles.app}>
      <ThemeProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/s"
              element={<GamePage mode={GameModesEnum.STANDARD} />}
            />
            <Route path="/e" element={<GamePage mode={GameModesEnum.EXPERT} />} />
            <Route path="/:encodedWord" element={<SharedGamePage />} />
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
};

export default App;
