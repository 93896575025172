import React from 'react';
import styles from './styles.module.scss';

const Loader: React.FC = () => (
  <div className={styles.loaderWrapper}>
    <span className={styles.loader} />
  </div>
);

export default Loader;
