import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../components';
import { PrimaryButton } from '../../components/Buttons';
import { HelpDialog, SettingsDialog } from '../../components/Dialogs';
import {
  keys,
  loadDialogShownFromLocalStorage,
  setDialogShownInLocalStorage
} from '../../shared/utils/localStorage';
import styles from './style.module.scss';

function LangingPage(): JSX.Element {
  const navigate = useNavigate();
  const [showHelpDialog, setShowHelpDialog] = useState<boolean>(false);
  const [showSettingsDialog, setShowSettingsDialog] = useState<boolean>(false);
  const [helpDialogShown, setHelpDialogShown] = useState<boolean>(
    loadDialogShownFromLocalStorage(keys.helpDialogShownKey)
  );

  const handleStandartClick = (): void => {
    navigate('/s');
  };

  const handleExpertClick = (): void => {
    navigate('/e');
  };

  const handleHelpDialogClose = (): void => {
    setShowHelpDialog(false);
    setHelpDialogShown(true);
    setDialogShownInLocalStorage(keys.helpDialogShownKey);
  };

  return (
    <>
      <Header
        showStats={false}
        onClickSettings={() => setShowSettingsDialog(true)}
        onClickHelp={() => setShowHelpDialog(true)}
      />
      <div className={styles.langingPageWrapper}>
        <nav className={styles.standardWrapper}>
          <PrimaryButton text="Standard" onClick={handleStandartClick} />
          <p className={styles.buttonDescription}>4-letter words</p>
        </nav>
        <nav>
          <PrimaryButton text="Expert" onClick={handleExpertClick} />
          <p className={styles.buttonDescription}>5-letter words</p>
        </nav>
      </div>
      {(showHelpDialog || !helpDialogShown) && (
        <HelpDialog onClose={handleHelpDialogClose} />
      )}
      {showSettingsDialog && (
        <SettingsDialog onClose={() => setShowSettingsDialog(false)} />
      )}
    </>
  );
}

export default LangingPage;
