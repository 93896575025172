export const getGuessStatus = (guess: string, solution: string): {correct: number, present: number} => {
  const splitSolution = solution.split('').map((x) => x.toUpperCase());
  const splitGuess = guess.split('');

  const status: {correct: number, present: number} = { correct: 0, present: 0 };
  for (let i = 0; i < splitGuess.length; i++) {
    if (splitGuess[i] === splitSolution[i]) {
      status.correct++;
      splitSolution[i] = '';
      splitGuess[i] = '';
    }
  }

  const splitGuessLeft = splitGuess.filter((x) => x !== '');
  const splitSolutionLeft = splitSolution.filter((x) => x !== '');
  for (let i = 0; i < splitGuessLeft.length; i++) {
    if (splitSolutionLeft.indexOf(splitGuessLeft[i]) > -1) {
      status.present++;
      splitSolutionLeft[splitSolutionLeft.indexOf(splitGuessLeft[i])] = '';
    }
  }

  return status;
};
