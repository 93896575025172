import React from 'react';
import { ReactComponent as HelpIcon } from '../../../../assets/icons/help.svg';
import styles from './styles.module.scss';
import colors from '../../../../shared/scss/colors.module.scss';

interface HelpButtonProps {
  onClick: (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const HelpButton: React.FC<HelpButtonProps> = ({
  onClick
}: HelpButtonProps) => (
  <button
    className={styles.helpButton}
    type="button"
    aria-label="help"
    onClick={onClick}
  >
    <HelpIcon fill={colors.darkgrey} />
  </button>
);

export default HelpButton;
