import React from 'react';
import { ReactComponent as StatisticsIcon } from '../../../../assets/icons/statictics.svg';
import styles from './styles.module.scss';
import colors from '../../../../shared/scss/colors.module.scss';

interface StatisticsButtonProps {
  onClick?: (_event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const StatisticsButton: React.FC<StatisticsButtonProps> = ({
  onClick
}: StatisticsButtonProps) => (
  <button
    className={styles.statisticsButton}
    type="button"
    aria-label="statistics"
    onClick={(e) => {
      if (onClick) onClick(e);
    }}
  >
    <StatisticsIcon fill={colors.darkgrey} />
  </button>
);

export default StatisticsButton;
