import React from 'react';
import styles from './styles.module.scss';

interface TileProps {
  value?: string;
  shouldActivateAnimation?: boolean;
  shouldDisplayBadge?: boolean;
  backgroundColor?: string;
}

const Tile: React.FC<TileProps> = ({
  value,
  shouldActivateAnimation,
  shouldDisplayBadge,
  backgroundColor,
  ...rest
}: TileProps) => {
  const dataObj = {
    'data-badge-display': shouldDisplayBadge ? 'block' : 'none'
  };
  return (
    <div
      className={`${styles.tile} ${value ? styles.lighterBorder : ''} ${
        shouldActivateAnimation ? styles.animate : ''
      }`}
      style={{ backgroundColor }}
      {...dataObj}
      {...rest}
    >
      <span>{value}</span>
    </div>
  );
};

export default Tile;
