import React, { ChangeEvent } from 'react';

import styles from './styles.module.scss';

interface ToggleSwitchProps {
  id: string;
  name: string;
  checked: boolean;
  onChange: (_checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  required?: boolean;
  size?: 'small' | 'normal';
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  id,
  name,
  checked,
  onChange,
  disabled,
  className = '',
  required,
  size = 'normal'
}: ToggleSwitchProps) => {
  const handleOnChange = (e: ChangeEvent<HTMLInputElement>): void => {
    onChange(e.target.checked);
  };

  return (
    <label
      htmlFor={id}
      className={`${className} ${styles.switch} ${styles[size]}`}
    >
      <input
        type="checkbox"
        name={name}
        id={id}
        checked={checked}
        onChange={handleOnChange}
        disabled={disabled}
        required={required}
      />
      <span className={`${styles.slider} ${styles.round}`} />
    </label>
  );
};

ToggleSwitch.defaultProps = {
  disabled: false
};

export default ToggleSwitch;
