import React from 'react';
import styles from './styles.module.scss';

interface CountdownProps {
  timeLeft: string;
}

const Countdown: React.FC<CountdownProps> = ({ timeLeft }: CountdownProps) => (
  <div className={styles.countdownWrapper}>
    <p className={styles.countdownText}>Come back in</p>
    <span className={styles.countdown}>{timeLeft}</span>
  </div>
);

export default Countdown;
