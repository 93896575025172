/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { GameModesEnum } from '../../../shared/utils/types';
import {
  GAME_CONFIG,
  GAME_TITLE_LOWERCASE,
  MODES
} from '../../../shared/utils/constants';
import { CompletedRow } from '../../Board/components';
import Dialog from '../BaseDialog/BaseDialog';
import styles from './styles.module.scss';

interface HelpDialogProps {
  onClose: () => void;
}

const HelpDialog: React.FC<HelpDialogProps> = ({
  onClose
}: HelpDialogProps) => (
  <Dialog
    dialogStyles={styles.helpDialog}
    dialogContentStyles={styles.helpDialog}
    isTextUppercase
    title="How to play"
    onClose={onClose}
  >
    <div>
      <p>
        {GAME_TITLE_LOWERCASE} generates a random word each time it's played. You have a limited number of guesses to figure it out. Each attempt must be a valid word. Hit the Enter button to submit.
      </p>
      <p>
        Feedback is given after each guess. Green dots represent letters in the right place, and yellow dots show how many letters are correct, but in the wrong place.
      </p>
      <p>
        Keep in mind, dots don’t indicate which letters they’re referring to!
      </p>
      <b>Sample game:</b>
      <br />
      <br />
      <div className={styles.notesContainer}>
        <CompletedRow
          guess="Frame"
          modeSettings={GAME_CONFIG[GameModesEnum.STANDARD]}
          mode={MODES.STANDARD}
          status={{ present: 2, correct: 0 }}
          rowStyles={styles.exampleRow}
          evaluationsStyles={styles.rowEvaluations}
        />
        <p className={styles.rowDescription}>
          Two letters are in the word but in the wrong spots.
        </p>
        <CompletedRow
          guess="Party"
          modeSettings={GAME_CONFIG[GameModesEnum.STANDARD]}
          mode={MODES.STANDARD}
          status={{ correct: 2, present: 0 }}
          rowStyles={styles.exampleRow}
          evaluationsStyles={styles.rowEvaluations}
        />
        <p className={styles.rowDescription}>Two letters in the right spots.</p>
        <CompletedRow
          guess="Barns"
          modeSettings={GAME_CONFIG[GameModesEnum.STANDARD]}
          mode={MODES.STANDARD}
          status={{ correct: 3, present: 1 }}
          rowStyles={styles.exampleRow}
          evaluationsStyles={styles.rowEvaluations}
        />
        <p className={styles.rowDescription}>
          Three letters in the right spots, one in the wrong spot.
        </p>
        <CompletedRow
          guess="Baron"
          modeSettings={GAME_CONFIG[GameModesEnum.STANDARD]}
          mode={MODES.STANDARD}
          status={{ correct: 5, present: 0 }}
          rowStyles={styles.exampleRow}
          evaluationsStyles={styles.rowEvaluations}
        />
        <p className={styles.rowDescription}>You got it right!</p>
      </div>
    </div>
  </Dialog>
);

export default HelpDialog;
