import { GameModesEnum } from './types';

/* eslint-disable @typescript-eslint/ban-ts-comment */
export function sendSharedEvent(wordmixtId: number): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event: 'share',
    label: wordmixtId
  });
}

export function sendPlayAgainEvent(): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event: 'playAgain'
  });
}

export function sendGameStartEvent(wordmixtId: number, category: GameModesEnum): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event: 'gameStart',
    category: category === GameModesEnum.STANDARD ? '4tile' : '5tile',
    label: wordmixtId
  });
}

export function sendGameGuessEvent(wordmixtId: number, category: GameModesEnum,
  value: number): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event: 'gameGuess',
    category: category === GameModesEnum.STANDARD ? '4tile' : '5tile',
    label: wordmixtId,
    value: value + 1
  });
}

export function sendGameOverEvent(wordmixtId: number, category: GameModesEnum,
  value: number): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push({
    event: 'gameOver',
    category: category === GameModesEnum.STANDARD ? '4tile' : '5tile',
    label: wordmixtId,
    value
  });
}

export function sendEnterFromSocialEvent(wordmixtId: number): void {
  // @ts-ignore
  window.dataLayer = window.dataLayer || [];

  // @ts-ignore
  window.dataLayer.push({
    event: 'enterFromSocial',
    label: wordmixtId,
  });
}
