const addZeroInFront = (num: number): string => `0${num}`.slice(-2);

export const calculateTimeTillMidnight = (): string | null => {
  const midnight = new Date();
  midnight.setHours(24);
  midnight.setMinutes(0);
  midnight.setSeconds(0);
  midnight.setMilliseconds(0);
  const difference = +new Date(midnight) - +new Date();

  if (difference < 0) return null;
  const hours = addZeroInFront(
    Math.floor((difference / (1000 * 60 * 60)) % 24)
  );
  const minutes = addZeroInFront(Math.floor((difference / 1000 / 60) % 60));
  const seconds = addZeroInFront(Math.floor((difference / 1000) % 60));
  return `${hours}:${minutes}:${seconds}`;
};
